import React, { useState, useEffect, useRef, memo } from 'react';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import { Link } from 'gatsby';
import SubNav from './SubNav';
import DownArrow from './DownArrow';
import { colors } from '../../utils/variables';

const NavItem = ({ navItem, slug }) => {
  const navLinkRef = useRef(null);
  const isActive = `/${slug}` === navItem.slug;

  const [subNavOffset, setSubNavOffset] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(undefined);

  const enableSubMenu = slug => {
    setIsHovered(true);
    setActiveSubMenu(slug);
  };

  const disableSubMenu = () => {
    setIsHovered(false);
    setActiveSubMenu(undefined);
  };

  const hoverEffectSpring = useSpring({
    backgroundColor: colors.blue,
    transform:
      isHovered || isActive
        ? 'translate3d(0, 0, 0)'
        : 'translate3d(-100%, 0, 0)',
    from: {
      backgroundColor: colors.blue,
      transform:
        isHovered || isActive
          ? 'translate3d(0, 0, 0)'
          : 'translate3d(-100%, 0, 0)'
    }
  });

  useEffect(() => {
    const { offsetLeft } = navLinkRef.current;
    setSubNavOffset(offsetLeft);
  });

  return (
    <Base
      onMouseEnter={() => enableSubMenu(navItem.slug)}
      onMouseLeave={disableSubMenu}
      ref={navLinkRef}
    >
      <NavLink to={navItem.slug}>
        <span>
          {navItem.name}
          {navItem.children && (
            <DownArrow isActive={activeSubMenu === navItem.slug} />
          )}
        </span>
        <HoverEffect style={hoverEffectSpring} />
      </NavLink>
      {navItem.children && (
        <SubNav
          isActive={activeSubMenu === navItem.slug}
          navItem={navItem}
          subNavOffset={subNavOffset}
        />
      )}
    </Base>
  );
};

export default memo(NavItem);

const Base = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-bottom: 0;
  margin-right: 1.875rem;

  &:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

const NavLink = styled(Link)`
  align-items: center;
  color: ${colors.blue};
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  z-index: 1;
`;

const HoverEffect = styled(animated.span)`
  bottom: 1.5rem;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
`;
