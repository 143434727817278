import React, { memo } from 'react';
import { Global, css } from '@emotion/core';
import emotionReset from 'emotion-reset';

const GlobalStyle = () => {
  return (
    <>
      <Global styles={styles} />
    </>
  );
};

export default memo(GlobalStyle);

const styles = css`
  ${emotionReset}

  * {
    box-sizing: border-box;
    font-kerning: auto;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;
