import React, { memo } from 'react';
import { css } from '@emotion/core';
import Layout from '../components/shared/Layout';
import Container from '../components/styled/Container';

const NotFoundPage = () => (
  <Layout theme="secondary">
    <Container style={containerCSS}>
      <h1>NOT FOUND</h1>
      <p>Sorry! Nothing here to see.</p>
    </Container>
  </Layout>
);

export default memo(NotFoundPage);

const containerCSS = css`
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  flex: 1;
`;
