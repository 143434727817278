import React, { memo } from 'react';
import { css } from '@emotion/core';
import logo from '../../images/aetheros-logo.png';

const Logo = ({ theme, style }) => {
  return (
    <h1 css={baseCSS}>
      <img css={logoCSS} src={logo} alt="Aetheros" />
    </h1>
  );
};

export default memo(Logo);

const baseCSS = css`
  margin-bottom: 0;
`;

const logoCSS = css`
  height: 6rem;
  margin: 0;
`;
