import React, { memo } from 'react';
import { css } from '@emotion/core';

const Container = ({ children, theme, style }) => (
  <div
    css={css`
      ${baseCSS}
      max-width: ${theme === 'secondary' ? '62.5rem' : '75rem'};
      ${style}
    `}
  >
    {children}
  </div>
);

export default memo(Container);

const baseCSS = css`
  margin: 0 auto;
  width: 90%;
`;
