export const colors = {
  black: '#333',
  blue: '#093560',
  darkBlue: '#06223d',
  gray: '#717171',
  green: '#99CC00',
  red: '#FC4600'
};

export const styles = {
  boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.08)'
};

export const viewports = {
  medium: 'screen and (min-width: 768px)'
};
