import React, { memo } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { colors, viewports } from '../../utils/variables';
import Container from '../styled/Container';
import SocialIcon from '../shared/SocialIcon';
import logo from '../../images/aetheros-icon.png';

const nav = [
  // { name: 'Products', slug: '/products' },
  { name: 'Partners', slug: '/partners' },
  // { name: 'News', slug: '/news' },
  // { name: 'About', slug: '/about' },
  { name: 'Contact', slug: '/contact' }
];

const Footer = () => {
  return (
    <footer css={baseCSS}>
      <Container>
        <div css={rowCSS}>
          <img css={logoCSS} src={logo} alt="Aetheros" />
          <nav css={navCSS}>
            {nav.map(navItem => (
              <Link css={navLinkCSS} to={navItem.slug} key={navItem.slug}>
                {navItem.name}
              </Link>
            ))}
          </nav>
        </div>
        <div css={rowCSS}>
          <div css={copyrightCSS}>
            Copyright © 2006 — {new Date().getFullYear()} Aetheros, Inc. All
            rights reserved.
          </div>
          <div css={socialCSS}>
            {/* <a
              css={socialLinkCSS}
              href="/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <SocialIcon platform="facebook" />
            </a> */}
            <a
              css={socialLinkCSS}
              href="https://www.linkedin.com/company/aetheros-inc/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <SocialIcon platform="linked-in" />
            </a>
            {/* <a
              css={socialLinkCSS}
              href="https://twitter.com/grid_net"
              rel="noopener noreferrer"
              target="_blank"
            >
              <SocialIcon platform="twitter" />
            </a> */}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default memo(Footer);

const baseCSS = css`
  background-color: ${colors.blue};
  padding: 3.125rem 0 2.8125rem;
  text-align: center;

  @media ${viewports.medium} {
    text-align: left;
  }
`;

const rowCSS = css`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${viewports.medium} {
    display: flex;
  }
`;

const logoCSS = css`
  height: 4rem;
`;

const navCSS = css`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;

  @media ${viewports.medium} {
    margin-top: 0;
  }
`;

const navLinkCSS = css`
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  margin-right: 1.875rem;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.green};
    }
  }
`;

const copyrightCSS = css`
  color: #fff;
  font-size: 0.75rem;
`;

const socialCSS = css`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  @media ${viewports.medium} {
    margin-top: 0;
  }
`;

const socialLinkCSS = css`
  margin-right: 3.125rem;

  &:last-of-type {
    margin-right: 0;
  }
`;
