import React, { memo } from 'react';
import { Link } from 'gatsby';
import { animated, useSpring } from 'react-spring';
import { css } from '@emotion/core';
import { viewports, colors } from '../../utils/variables';

const SubNav = ({ isActive, navItem, subNavOffset }) => {
  const spring = useSpring({
    y: isActive ? '0%' : '-100%',
    from: { y: '-100%' },
  });

  return (
    <div
      css={subNavContainerCSS}
      style={{
        pointerEvents: isActive ? 'auto' : 'none',
        display: isActive ? 'block' : 'none',
      }}
    >
      <animated.div
        css={subNavCSS}
        style={{
          transform: spring.y.interpolate(y => `translateY(${y})`),
        }}
      >
        <div css={navItemsCSS} style={{ paddingLeft: `${subNavOffset}px` }}>
          {navItem.children.map(navItemChild => (
            <Link
              to={navItemChild.slug}
              css={subNavMenuLinkCSS}
              key={navItemChild.slug}
            >
              {navItemChild.name}
            </Link>
          ))}
        </div>
      </animated.div>
    </div>
  );
};

export default memo(SubNav);

const subNavContainerCSS = css`
  height: 2.5rem;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
`;

const subNavCSS = css`
  background-color: #333;
  bottom: 0;
  height: 2.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const navItemsCSS = css`
  align-items: center;
  height: 100%;
  display: flex;
`;

const subNavMenuLinkCSS = css`
  color: #fff;
  font-size: 0.875rem;
  margin-right: 1.875rem;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.green};
    }
  }
`;
