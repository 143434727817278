import React, { useState, memo } from 'react';
import { css } from '@emotion/core';
import { animated, useSpring } from 'react-spring';
import { Link } from 'gatsby';
import MobileIcon from './MobileIcon';
import { colors, viewports } from '../../utils/variables';

const MobileNav = ({ isSecondary, navItems }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleMobileNav = () => setMobileNavOpen(!mobileNavOpen);

  const navLinkSpring = useSpring({
    color: isSecondary ? colors.blue : '#fff',
    from: { color: isSecondary ? colors.blue : '#fff' }
  });

  const mobileNavSpring = useSpring({
    x: mobileNavOpen ? '0' : '100%',
    from: { x: '100%' }
  });

  return (
    <>
      <animated.nav
        css={navCSS}
        style={{
          transform: mobileNavSpring.x.interpolate(x => `translateX(${x})`)
        }}
      >
        {navItems.map(navItem => (
          <Link css={navLinkCSS} to={navItem.slug} key={navItem.slug}>
            <animated.span style={navLinkSpring}>{navItem.name}</animated.span>
          </Link>
        ))}
      </animated.nav>
      <MobileIcon
        toggleMobileNav={toggleMobileNav}
        mobileNavOpen={mobileNavOpen}
      />
    </>
  );
};

export default memo(MobileNav);

const navCSS = css`
  align-items: center;
  background: ${colors.blue};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;

  @media ${viewports.medium} {
    display: none;
  }
`;

const navLinkCSS = css`
  align-items: center;
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  text-decoration: none;
  z-index: 1;
`;
