import React, { memo } from 'react';
import { css } from '@emotion/core';
import { animated, useSpring } from 'react-spring';
import { colors } from '../../utils/variables';

const DownArrow = ({ isActive, isSecondary }) => {
  const spring = useSpring({
    fill: isSecondary ? colors.blue : '#fff',
    from: { fill: isSecondary ? colors.blue : '#fff' },
  });

  return (
    <svg
      css={css`
        ${baseCSS}
        transform: ${isActive ? 'rotate(180deg)' : ''};
      `}
      viewBox="0 0 6 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.path
        style={spring}
        d="m70 11 3 6h-6z"
        fillRule="evenodd"
        transform="matrix(1 0 0 -1 -67 17)"
      />
    </svg>
  );
};

export default memo(DownArrow);

const baseCSS = css`
  height: 0.375rem;
  margin-left: 0.25rem;
  position: relative;
  top: -1px;
  width: 0.375rem;
`;
