import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';
import GlobalStyle from '../styled/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import socialImage from '../../images/aetheros-logo.png';

const socialTitle = `Aetheros - Let's Connect Our World`;
const socialUrl = 'https://aetheros.com';
const socialDescription =
  'We are a highly innovative and entrepreneurial team whose passion is building large-scale network for machines. We believe in open source and open standards, and our products are developed to meet the most stringent government and industry security requirements.';

const Layout = ({ children, theme, slug }) => {
  return (
    <StaticQuery query={SiteQuery}>
      {({ site }) => (
        <>
          <Helmet title={site.siteMetadata.title}>
            <html lang="en" />
            <meta charSet="UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <meta property="og:url" content={socialUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={socialTitle} />
            <meta property="og:description" content={socialDescription} />
            <meta property="og:image" content={`${socialImage}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={socialUrl} />
            <meta name="twitter:description" content={socialDescription} />
            <meta name="twitter:image" content={socialImage} />
          </Helmet>
          <GlobalStyle />
          <div css={baseCSS}>
            <Header theme={theme} slug={slug} />
            {children}
            <Footer />
          </div>
        </>
      )}
    </StaticQuery>
  );
};

export default memo(Layout);

const SiteQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const baseCSS = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
