import React, { memo } from 'react';
import { css } from '@emotion/core';
import { animated, useSpring } from 'react-spring';
import { colors, viewports } from '../../utils/variables';

const MobileIcon = ({ toggleMobileNav, mobileNavOpen }) => {
  const spring = useSpring({
    stroke: !mobileNavOpen ? colors.blue : '#fff',
    from: { stroke: !mobileNavOpen ? colors.blue : '#fff' }
  });

  return (
    <svg
      css={baseCSS}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      onClick={toggleMobileNav}
    >
      <animated.line
        style={{
          ...spring,
          transform: mobileNavOpen
            ? 'rotate(45deg) translate3d(10px, -8px, 0)'
            : ''
        }}
        x1="0"
        x2="48"
        y1="8"
        y2="8"
      />
      <animated.line
        style={{ ...spring, opacity: mobileNavOpen ? 0 : 1 }}
        x1="0"
        x2="48"
        y1="24"
        y2="24"
      />
      <animated.line
        style={{
          ...spring,
          transform: mobileNavOpen
            ? 'rotate(-45deg) translate3d(-24px, -6px, 0)'
            : ''
        }}
        x1="0"
        x2="48"
        y1="40"
        y2="40"
      />
    </svg>
  );
};

export default memo(MobileIcon);

const baseCSS = css`
  display: block;
  height: 1.75rem;
  stroke-width: 6;
  width: 1.75rem;
  z-index: 1000;

  & line {
    transition: 200ms ease-out;
  }

  @media ${viewports.medium} {
    display: none;
  }
`;
