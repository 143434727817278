import React, { memo } from 'react';
import { css } from '@emotion/core';
import { viewports } from '../../utils/variables';
import NavItem from './NavItem';

const Nav = ({ navItems, slug }) => {
  return (
    <nav css={baseCSS}>
      {navItems.map(navItem => (
        <NavItem navItem={navItem} key={navItem.slug} slug={slug} />
      ))}
    </nav>
  );
};

export default memo(Nav);

const baseCSS = css`
  align-items: center;
  display: none;
  height: 100%;

  @media ${viewports.medium} {
    display: flex;
  }
`;
