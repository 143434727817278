import React, { memo } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import Logo from './Logo';
import { viewports } from '../../utils/variables';
import MobileNav from './MobileNav';
import Nav from './Nav';

const navItems = [
  // {
  //   name: 'Products',
  //   slug: '/products',
  //   children: [
  //     {
  //       name: 'Smart Grid Applications',
  //       slug: '/products/smart-grid-applications'
  //     },
  //     { name: 'IoT Network Platform', slug: '/products/iot-network-platform' }
  //   ]
  // },
  { name: 'Partners', slug: '/partners' },
  // { name: 'News', slug: '/news' },
  // { name: 'About', slug: '/about' },
  { name: 'Contact', slug: '/contact' }
];

const Header = ({ theme, slug }) => {
  return (
    <>
      <header css={baseCSS}>
        <div css={backgroundCSS} />
        <Link to="/">
          <Logo />
        </Link>
        <Nav navItems={navItems} />
        <MobileNav navItems={navItems} />
      </header>
    </>
  );
};

export default memo(Header);

const baseCSS = css`
  align-items: center;
  display: flex;
  height: 7.5rem;
  justify-content: space-between;
  left: 0;
  padding: 0 5%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  @media ${viewports.medium} {
    padding: 0 3.25rem;
  }
`;

const backgroundCSS = css`
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;
