import React, { memo } from 'react';
import { css } from '@emotion/core';
import { viewports, colors } from '../../utils/variables';

const SocialIcon = ({ platform }) => {
  if (platform === 'facebook') {
    return (
      <svg
        height="20"
        viewBox="0 0 11 20"
        width="11"
        xmlns="http://www.w3.org/2000/svg"
        css={baseCSS}
      >
        <path d="m10.3276289.00412371-2.57010313-.00412371c-2.88742268 0-4.75340206 1.91443299-4.75340206 4.87752577v2.24886598h-2.58412371c-.22329897 0-.40412371.18103093-.40412371.4043299v3.25835055c0 .2232989.18103093.4041237.40412371.4041237h2.58412371v8.2218556c0 .223299.18082474.4041238.40412371.4041238h3.37154639c.22329897 0 .40412372-.181031.40412372-.4041238v-8.2218556h3.02144327c.223299 0 .4041237-.1808248.4041237-.4041237l.0012371-3.25835055c0-.1072165-.0426804-.20989691-.1183505-.2857732s-.1787629-.1185567-.2859793-.1185567h-3.02247427v-1.90639175c0-.91628866.21835051-1.3814433 1.41195876-1.3814433l1.73134021-.00061856c.2230928 0 .4039175-.18103092.4039175-.40412371v-3.02556701c0-.22288659-.1806185-.40371134-.4035051-.40412371z" />
      </svg>
    );
  }

  if (platform === 'linked-in') {
    return (
      <svg
        height="20"
        viewBox="0 0 21 20"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
        css={baseCSS}
      >
        <path d="m20.7970164 12.2126827v7.6871085h-4.4592467v-7.1758437c0-1.8041493-.6429628-3.03031493-2.2564806-3.03031493-1.2339509 0-1.9618563.83015833-2.2867954 1.62908833-.1169972.2877088-.1525313.6906316-.1525313 1.0883786v7.4877784h-4.45485384s.05980341-12.14892139 0-13.40714164h4.45576724v1.90031315c-.0060456.01561413-.0190501.02857516-.0251392.04592902h.0251392v-.04592902c.595294-.91331768 1.6498782-2.21490084 4.0181367-2.21490084 2.9393267.0000435 5.1360039 1.91597078 5.1360039 6.03553413zm-18.27453032-12.18532536c-1.52422582 0-2.52248608 1-2.52248608 2.31889353 0 1.28335943.96881524 2.31715379 2.46529228 2.31715379h.02687891c1.55802018 0 2.52335595-1.03379436 2.52335595-2.31715379-.03031489-1.31889353-.96529228-2.31889353-2.49304106-2.31889353zm-2.25643702 19.87239036h4.45663709v-13.40883782h-4.45663709z" />
      </svg>
    );
  }

  if (platform === 'twitter') {
    return (
      <svg
        height="20"
        viewBox="0 0 25 20"
        width="25"
        xmlns="http://www.w3.org/2000/svg"
        css={baseCSS}
      >
        <path d="m24.5783133 2.37983936c-.9046185.40084337-1.8752611.67269076-2.8951004.79405622 1.0413253-.62357429 1.8384337-1.6126506 2.2162249-2.78759036-.9767872.57746988-2.05498.99678715-3.2038153 1.22409639-.9199598-.98140563-2.2285141-1.59269077-3.6799197-1.59269077-2.784498 0-5.0422088 2.25771085-5.0422088 5.04068273 0 .3946988.0445381.78020081.1305622 1.14883535-4.18979917-.21044177-7.90502006-2.21779117-10.39156624-5.26803213-.43465863.74337349-.68192771 1.60955823-.68192771 2.5341767 0 1.74935743.89080321 3.29289157 2.24236948 4.19594378-.82630522-.02763052-1.60345382-.25493976-2.28381526-.63277109v.06297189c0 2.44200803 1.73859437 4.48008033 4.04389558 4.94393573-.42236948.1136546-.86775101.1766265-1.32851406.1766265-.32558233 0-.64044177-.032249-.94915662-.0936948.64196787 2.0042972 2.50345381 3.4618073 4.70895582 3.5017671-1.72477912 1.3515663-3.89955823 2.1548193-6.26168675 2.1548193-.40698795 0-.80787148-.0245783-1.20257028-.0691165 2.23160643 1.4329719 4.88096386 2.2684739 7.72843374 2.2684739 9.2750201 0 14.3448996-7.6823695 14.3448996-14.34489958l-.0169076-.65273092c.9906024-.70654619 1.8475903-1.59425703 2.5218474-2.60485944z" />
      </svg>
    );
  }

  return null;
};

export default memo(SocialIcon);

const baseCSS = css`
  fill: #fff;

  @media ${viewports.medium} {
    &:hover {
      fill: ${colors.green};
    }
  }
`;
